export default [
    {
        path: "/",
        redirect: "/KbbScreen",
    },
    {
        path: "/Question",
        name: "Question",
        component: () => import("@/views/Question.vue"),
    }, {
        path: "/AnswerDetails",
        name: "AnswerDetails",
        component: () => import("@/views/AnswerDetails.vue"),
    }, {
        path: "/Answer",
        name: "Answer",
        component: () => import("@/views/Answer.vue"),
    }, {
        path: "/KbbScreen",
        name: "KbbScreen",
        component: () => import("@/views/Kbb-Screen.vue"),
    },
]
