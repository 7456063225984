import {createRouter, createWebHistory} from 'vue-router';
import DefaultRoutes from "@/router/default-routes";

const routes = DefaultRoutes;
// 创建router实例
const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;